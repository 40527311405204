import React, { useState, useEffect, Fragment } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";

import { AiOutlineMenu } from "react-icons/ai";

import WorkTable from "../Components/WorkTable";

import * as querystring from "query-string";

function getAssignments(token, setAssignments) {
  const BASE_URL = `${process.env.REACT_APP_FMS_BASE_URL}/api/v1/`;
  const ASSIGNMENT_PATH = "assignments";
  const url = `${BASE_URL}${ASSIGNMENT_PATH}`;

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const getInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, getInit)
    .then(handleErrors)
    .then((assignmentResponse) => {
      return assignmentResponse.json();
    })
    .then((assignmentJson) => {
      setAssignments(assignmentJson);
    });
}

function ContractorHome(props) {
  const [assignments, setAssignments] = useState([]);
  const coverageStatus = querystring.parse(props.queryParams);

  useEffect(() => {
    getAssignments(props.token, setAssignments);
  }, []);

  return (
    <Fragment>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
          <AiOutlineMenu />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Link href="/search">
            <i className="fa fa-search" aria-hidden="true"></i>
          </Nav.Link>
          <Nav.Link href="/alerts">
            <i className="fa fa-bell"></i>
          </Nav.Link>
          <Nav.Link href="/profile">
            <div className="user-pic">
              <Image
                rounded
                width={40}
                height={40}
                className="img-responsive"
                src="https://images.pexels.com/photos/3394658/pexels-photo-3394658.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=100"
                alt="User profile"
              />
            </div>
          </Nav.Link>
        </Nav>
      </Navbar>
      <WorkTable assignments={assignments} coverageStatus={coverageStatus} />
    </Fragment>
  );
}

export default ContractorHome;
