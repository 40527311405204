import React from 'react';
import PropTypes from 'prop-types';

import AuthWrapper from '../Components/AuthWrapper';

class ConfirmForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      verificationCode: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.verificationCode)
      .then((user) => {
        console.log(user);
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  onResend = (event) => {
    event.preventDefault();
    this.props.onResend()
      .then((user) => {
        this.setState({ error: 'Code resent' });
      })
      .catch((error) => {
        this.setState({ error });
      });

  }

  changeVerificationCode = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  render = () => (
    <AuthWrapper>
      <form onSubmit={this.onSubmit}>
        <fieldset id="login" className="ba b--transparent ph0 mh0 dark-gray">
          <div className="brand-pink mb2">{this.state.error}</div>
          <div className="form-group">
            <label htmlFor="email-verification">Enter verification code</label>
            <input
              id="email-verification"
              className="form-control"
              onChange={this.changeVerificationCode}
              type="text"
              required />
          </div>
        </fieldset>
        <input
          className="form-control"
          value="Submit"
          type="submit" />
        <p>
          <span className="btn btn-primary mt-4" onClick={this.onResend}>Resend code</span>
        </p>
      </form>
    </AuthWrapper>
  )
}
ConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onResend: PropTypes.func,
  error: PropTypes.string,
};

export default ConfirmForm;
