import React from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

function WorkTable(props) {
  return (
    <Container>
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Flat Fee</th>
            <th>Required insurance</th>
          </tr>
        </thead>
        <tbody>
          {props.assignments.length > 0 &&
            props.assignments.map((item) => (
              <>
                <tr>
                  <td>{item.json.locationnumber}</td>
                  <td>{item.json.title}</td>
                  <td>{item.json.description}</td>
                  <td>$800</td>
                  <td>
                    {props.coverageStatus.eligible == "wc" ? (
                      <span>Apply now</span>
                    ) : (
                      <a href="https://insure.1099policy.com/">
                        Workers compensation
                      </a>
                    )}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default WorkTable;
