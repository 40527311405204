import React from "react";
import classNames from "classnames";

import { Logout } from "react-cognito";
import { SlideDown } from "react-slidedown";

import "react-slidedown/lib/slidedown.css";

function SideNavHeader() {
  return (
    <div className="sidebar-brand">
      <a href="home">1099 jobcloud</a>
      <div id="close-sidebar">
        <i className="fas fa-times"></i>
      </div>
    </div>
  );
}

function SideNavProfile() {
  return (
    <div className="sidebar-header">
      <div className="user-pic">
        <img
          className="img-responsive img-rounded"
          src="https://images.pexels.com/photos/2380794/pexels-photo-2380794.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=100"
          alt="User profile"
        />
      </div>
      <div className="user-info">
        <span className="user-name">
          James
          <strong>Scott</strong>
        </span>
        <span className="user-role">Administrator</span>
        <span className="user-status">
          <i className="fa fa-circle"></i>
          <span>Online</span>
        </span>
      </div>
    </div>
  );
}

function SideNavSearch() {
  return (
    <div className="sidebar-search">
      <div>
        <div className="input-group">
          <input
            type="text"
            className="form-control search-menu"
            placeholder="Search..."
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

class SideNavDropdownWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "work",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ active: e.currentTarget.id });
  }

  render() {
    const handleClick = this.handleClick;
    const active = this.state.active;

    function updateChild(child) {
      const dropdownClass = classNames(
        {
          active: child.props.refId === active,
        },
        "sidebar-dropdown"
      );
      const props =
        child.type !== "li"
          ? {
              handleClick,
              dropdownClass,
              active,
              ...child.props,
            }
          : {
              ...child.props,
            };
      return React.cloneElement(child, props);
    }

    return (
      <ul>
        {React.Children.map(this.props.children, (child) => updateChild(child))}
      </ul>
    );
  }
}

class SideNavDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleClick, dropdownClass, active } = this.props;

    function updateChild(child) {
      const props = !child.props.subId
        ? {
            handleClick,
            ...child.props,
          }
        : {
            active,
            ...child.props,
          };
      return React.cloneElement(child, props);
    }

    return (
      <li className={dropdownClass}>
        {React.Children.map(this.props.children, (child) => updateChild(child))}
      </li>
    );
  }
}

class SideNavDropdownTitle extends React.Component {
  render() {
    const { id, href, handleClick } = this.props;

    return (
      <a id={id} href={href} onClick={handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class SideNavSubmenu extends React.Component {
  render() {
    const { active, children, subId, ...restProps } = this.props;
    const open = subId === active;
    const sideMenu = <ul>{children}</ul>;

    return (
      <SlideDown className={"sidebar-submenu"}>
        {open ? sideMenu : null}
      </SlideDown>
    );
  }
}

function SideNavListItem(children) {
  return (
    <li>
      <a href="#">{children}</a>
    </li>
  );
}

function SideNavMenu() {
  return (
    <div className="sidebar-menu">
      <SideNavDropdownWrapper>
        <li className="header-menu">
          <span>General</span>
        </li>
        <SideNavDropdown refId="work">
          <SideNavDropdownTitle id="work" href="#">
            <i className="fa fa-briefcase"></i>
            <span>Work</span>
            <span className="badge badge-pill badge-warning">New</span>
          </SideNavDropdownTitle>
          <SideNavSubmenu subId="work">
            <li>
              <a href="#">Assignments</a>
            </li>
            <li>
              <a href="#">New Assignment</a>
            </li>
            <li>
              <a href="#">Projects</a>
            </li>
            <li>
              <a href="#">Contact Manager</a>
            </li>
            <li>
              <a href="#">WorkUpload</a>
            </li>
            <li>
              <a href="#">Realtime</a>
            </li>
          </SideNavSubmenu>
        </SideNavDropdown>
        <SideNavDropdown refId="talent">
          <SideNavDropdownTitle id="talent" href="#">
            <i className="fa fa-user"></i>
            <span>Talent</span>
            <span className="badge badge-pill badge-danger">3</span>
          </SideNavDropdownTitle>
          <SideNavSubmenu subId="talent">
            <li>
              <a href="#">Find Talent</a>
            </li>
            <li>
              <a href="#">Talent Pools</a>
            </li>
            <li>
              <a href="#">Recruiting</a>
            </li>
            <li>
              <a href="#">Invitations</a>
            </li>
            <li>
              <a href="#">Tests</a>
            </li>
            <li>
              <a href="#">Surveys</a>
            </li>
          </SideNavSubmenu>
        </SideNavDropdown>
        <SideNavDropdown refId="payments">
          <SideNavDropdownTitle id="payments" href="#">
            <i className="fas fa-university"></i>
            <span>Payments</span>
          </SideNavDropdownTitle>
          <SideNavSubmenu subId="payments">
            <li>
              <a href="#">Overview</a>
            </li>
            <li>
              <a href="#">Add Funds</a>
            </li>
            <li>
              <a href="#">Accounts</a>
            </li>
          </SideNavSubmenu>
        </SideNavDropdown>
        <SideNavDropdown refId="reports">
          <SideNavDropdownTitle id="reports" href="#">
            <i className="fa fa-chart-line"></i>
            <span>Reports</span>
          </SideNavDropdownTitle>
          <SideNavSubmenu subId="reports">
            <li>
              <a href="#">Dashboard</a>
            </li>
            <li>
              <a href="#">New Report</a>
            </li>
            <li>
              <a href="#">All Reports</a>
            </li>
          </SideNavSubmenu>
        </SideNavDropdown>
      </SideNavDropdownWrapper>
    </div>
  );
}

const LogoutButton = ({ onClick }) => (
  <a href="#" onClick={onClick}>
    <i className="fa fa-power-off"></i>
  </a>
);

function SideNavFooter() {
  return (
    <div className="sidebar-footer">
      <a href="#">
        <i className="fa fa-bell"></i>
        <span className="badge badge-pill badge-warning notification">3</span>
      </a>
      <a href="#">
        <i className="fa fa-envelope"></i>
        <span className="badge badge-pill badge-success notification">7</span>
      </a>
      <a href="#">
        <i className="fa fa-cog"></i>
        <span className="badge-sonar"></span>
      </a>
      <Logout>
        <LogoutButton />
      </Logout>
    </div>
  );
}

function SideNav() {
  return (
    <nav id="sidebar" className="sidebar-wrapper">
      <div className="sidebar-content">
        <SideNavHeader />
        <SideNavProfile />
        <SideNavSearch />
        <SideNavMenu />
      </div>
      <SideNavFooter />
    </nav>
  );
}

export default SideNav;
