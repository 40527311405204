import React from "react";

import { Modal, Button } from "react-bootstrap";
import CreateAssignment from "./CreateAssignment";

class AssignmentModal extends React.Component {
  render() {
    return (
      <Modal
        size="lg"
        show={this.props.show}
        onHide={this.props.handleHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Assignment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateAssignment
            token={this.props.token}
            handleHide={this.props.handleHide}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleHide}>
            Close
          </Button>
          <Button variant="primary" form="create-assignment" type="submit">
            Post Assignment
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class Home extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    let close = () => this.setState({ show: false });

    return (
      <div className="container-fluid">
        <h2>Cummins, Inc</h2>
        <hr />
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
            <div className="card rounded-0 p-3 shadow-sm">
              <i className="h1 text-center fas fa-search"></i>
              <div className="card-body text-center">
                <h6 className="card-title">Find Talent</h6>
                <hr />
                <p>
                  Invite your current freelancers or browse the talent
                  marketplace to find the right team for the job.
                  <br />
                  &nbsp;
                </p>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block"
                >
                  Find Talent
                </button>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
            <div className="card rounded-0 p-3 shadow-sm">
              <i className="h1 text-center fas fa-file-signature"></i>
              <div className="card-body text-center">
                <h6 className="card-title">Create Assignment</h6>
                <hr />
                <p>
                  Create an assignment, upload multiple assignments in bulk, or
                  create a project with multiple assignments.
                </p>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block"
                  onClick={this.handleShow}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <AssignmentModal
          show={this.state.show}
          handleHide={this.handleHide}
          token={this.props.token}
        />
      </div>
    );
  }
}

export default Home;
