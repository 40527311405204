import React, { Component } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactNotification from "react-notifications-component";

import LaborPlatform from "./LaborPlatform";
import RegisterForm from "./Pages/RegisterForm";

import "react-notifications-component/dist/theme.css";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <ReactNotification />
          <Route
            exact
            path="/"
            render={(props) => {
              return <LaborPlatform queryParams={props.location.search} />;
            }}
          />
          <Route exact path="/register" render={(props) => <RegisterForm />} />
        </div>
      </Router>
    );
  }
}

export default App;
