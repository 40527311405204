import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';


class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      username: props.username,
      password: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.username, this.state.password);
  }

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  componentWillUnmount = () => {
    this.props.clearCache();
  }

  render = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
      <form onSubmit={this.onSubmit}>
        <h2 className="text-center mb-4">1099<b>JobCloud</b></h2>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={this.state.username}
            onChange={this.changeUsername} />
          <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>

        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            onChange={this.changePassword} />
        </div>
        <small id="signUpHelp" className="form-text text-muted mb-4">Don't have an account? <Link className="brand-pink no-underline" to="/register">Create account</Link></small>
        <button type="submit" className="btn btn-primary">Sign in</button>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  clearCache: PropTypes.func,
  username: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string,
};

export default LoginForm;
