import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { CognitoState, Login, Confirm } from "react-cognito";
import ReactLoading from "react-loading";

import SideNav from "./Components/SideNav";

import Home from "./Pages/Home";
import LoginForm from "./Pages/LoginForm";
import RegisterForm from "./Pages/RegisterForm";
import ConfirmForm from "./Pages/ConfirmForm";
import ContractorHome from "./Pages/ContractorHome";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

let TOKEN = "";

function Find() {
  return (
    <div>
      <h2>Find</h2>
    </div>
  );
}

function Create({ match }) {
  return (
    <div>
      <h2>Create Assignment</h2>
      <ul>
        <li>
          <Link to={`${match.url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>

      <Route path={`${match.path}/:assignmentId`} component={Assignment} />
      <Route
        exact
        path={match.path}
        render={() => <h3>Please select an assignment.</h3>}
      />
    </div>
  );
}

function Assignment({ match }) {
  return (
    <div>
      <h3>{match.params.topicId}</h3>
    </div>
  );
}

function Fund({ match }) {
  return (
    <div>
      <h3>Fund</h3>
    </div>
  );
}

function getRole(token, setIsLoading, setIsAdmin) {
  const BASE_URL = `${process.env.REACT_APP_FMS_BASE_URL}/api/v1/`;
  const ROLE_PATH = "roles";
  const url = `${BASE_URL}${ROLE_PATH}`;

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const getInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, getInit)
    .then(handleErrors)
    .then((rolesResponse) => {
      return rolesResponse.json();
    })
    .then((rolesJson) => {
      setIsLoading(false);
      setIsAdmin(rolesJson.role === "admin");
    });
}

const renderRoutes = (token, queryParams) => (
  <Fragment>
    <ContractorHome token={token} queryParams={queryParams} />
  </Fragment>
);

const renderAdminRoutes = (token) => (
  <Fragment>
    <SideNav />
    <main className="page-content">
      <Route exact path="/" render={(props) => <Home token={token} />} />
      <Route exact path="/find" component={Find} />
      <Route exact path="/create" component={Create} />
      <Route exact path="/fund" component={Fund} />
      <Route exact path="/register" component={RegisterForm} token={TOKEN} />
    </main>
  </Fragment>
);

const loggedInPage = (user, attributes, queryParams) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  TOKEN = user.signInUserSession.idToken.jwtToken;

  getRole(TOKEN, setIsLoading, setIsAdmin);

  return (
    <Router>
      {!isLoading ? (
        <div className="page-wrapper chiller-theme toggled">
          <a id="show-sidebar" className="btn btn-sm btn-dark" href="/menu">
            <i className="fas fa-bars"></i>
          </a>
          {isAdmin
            ? renderAdminRoutes(TOKEN)
            : renderRoutes(TOKEN, queryParams)}
        </div>
      ) : (
        <ReactLoading
          type="spin"
          color="#000000"
          height={25}
          width={25}
          className="mx-auto mt-5"
        />
      )}
    </Router>
  );
};

const loggedOutPage = () => (
  <div>
    <section>
      <Login>
        <LoginForm />
      </Login>
    </section>
  </div>
);

const newPasswordPage = () => <div>Password page.</div>;

const emailVerificationPage = () => <div>Email verification.</div>;

const confirmForm = () => (
  <div>
    <Confirm>
      <ConfirmForm />
    </Confirm>
  </div>
);

const mfaPage = () => (
  <div>
    <p>You need to enter an MFA, but this library does not yet support them.</p>
  </div>
);

const BaseLaborPlatform = ({ state, user, attributes, queryParams }) => {
  switch (state) {
    case CognitoState.LOGGED_IN:
      return loggedInPage(user, attributes, queryParams);
    case CognitoState.AUTHENTICATED:
    case CognitoState.LOGGING_IN:
      return (
        <div>
          <img src="ajax-loader.gif" alt="" />
        </div>
      );
    case CognitoState.LOGGED_OUT:
    case CognitoState.LOGIN_FAILURE:
      return loggedOutPage();
    case CognitoState.MFA_REQUIRED:
      return mfaPage();
    case CognitoState.NEW_PASSWORD_REQUIRED:
      return newPasswordPage();
    case CognitoState.EMAIL_VERIFICATION_REQUIRED:
      return emailVerificationPage();
    case CognitoState.CONFIRMATION_REQUIRED:
      return confirmForm();
    default:
      return (
        <div>
          <p>Unrecognised cognito state</p>
        </div>
      );
  }
};

BaseLaborPlatform.propTypes = {
  user: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
  queryParams: PropTypes.string,
};

const mapStateToProps = (state) => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const LaborPlatform = connect(mapStateToProps, null)(BaseLaborPlatform);

export default LaborPlatform;
