import React from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { Nav, Container, Row, Col } from "react-bootstrap";
import { Check, Group, Label, Control } from "react-bootstrap/Form";

import { Formik, Form, Field, ErrorMessage } from "formik";

import ReactTags from "react-tag-autocomplete";
import ReactLoading from "react-loading";
import { store } from "react-notifications-component";

import { EditorState, ContentState } from "draft-js";
import { RichEditor } from "../Components/RichEditor";

import "../RichEditor.css";
import "../ReactTags.css";

const BASE_URL = `${process.env.REACT_APP_FMS_BASE_URL}/api/v1/`;
const PREFILL_PATH = "prefill";
const ASSIGNMENT_PATH = "assignments";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function AssignmentForm(props) {
  /* 
  Title
  Category
  Description
  Special Instructions
  Desired Skills
  Owner
  Support Contact
  Client
  Project
  Location
  Location Name
  Location Number
  Location Address
  Location Contact
  Schedule Options
  Date / Time
  Require Confirmation
  Require Check-in
  Payment Type
  Flat Fee
  Payment Terms
  Pay Outside of Platform
  Instructions
  Upload Document
  Invite Talent
  */

  const tags = [
    {
      id: 1,
      name: "Cisco",
    },
    {
      id: 2,
      name: "Oracle",
    },
  ];

  let handleDelete = (i) => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  };

  let handleAddition = (tag) => {
    const tags = [].concat(tags, tag);
    this.setState({ tags });
  };

  const defaultVals = {
    descriptionState: new EditorState.createWithContent(
      ContentState.createFromText(props.initVals.description)
    ),
    instructionState: new EditorState.createWithContent(
      ContentState.createFromText(props.initVals.special)
    ),
    tagState: tags,
    tags: tags,
    suggestions: [
      { id: 3, name: "Java" },
      { id: 4, name: "Windows" },
    ],
    ...props.initVals,
  };

  function showNotificaiton() {
    store.addNotification({
      title: "Assignment created!",
      message:
        "We've sent notifications to pool of 100+ indepdendent contractors.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  }

  const submitHandler = (handleHide, values, actions) => {
    actions.setSubmitting(true);

    const url = `${BASE_URL}${ASSIGNMENT_PATH}`;

    const postInit = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
      },
    };

    let { descriptionState, instructionState, ...updatedValues } = values;

    const init = {
      ...postInit,
      body: JSON.stringify(updatedValues),
    };

    const t = this;
    fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        actions.setSubmitting(false);
        showNotificaiton();
        handleHide();
      });
  };

  const handleHide = props.handleHide;

  return (
    <Formik
      initialValues={defaultVals}
      validate={{}}
      onSubmit={(values, actions) => submitHandler(handleHide, values, actions)}
    >
      {(props) => {
        return (
          <Form id="create-assignment">
            <Element name="basics">
              <h4 id="basics">The Basics</h4>
              <hr />
              <label className="font-weight-bold d-block" htmlFor="title">
                Title
              </label>
              <Field
                type="text"
                name="title"
                placeholder="Network Specialist..."
                value={props.values.title}
                className="form-control"
              />
              <ErrorMessage name="title" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="category"
              >
                Category
              </label>
              <Field
                component="select"
                name="category"
                className="form-control"
                value="machine-repair"
              >
                <option value="technology-and-communications">
                  Technology and Communications
                </option>
                <option value="finance-backoffice">Finance Backoffice</option>
                <option value="support-specialist">Support Specialist</option>
                <option value="machine-repair">
                  Office Machines and Appliance Repair
                </option>
              </Field>
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="description"
              >
                Description
              </label>
              <RichEditor
                editorState={props.values.descriptionState}
                onChange={props.setFieldValue}
                onBlur={props.handleBlur}
              />
              <ErrorMessage name="description" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="special"
              >
                Special Instructions
              </label>
              <RichEditor
                editorState={props.values.instructionState}
                onChange={props.setFieldValue}
                onBlur={props.handleBlur}
              />
              <ErrorMessage name="special" component="div" />
              <label className="font-weight-bold d-block mt-3" htmlFor="skills">
                Desired Skills
              </label>
              <ReactTags
                tags={props.values.tagState}
                suggestions={props.values.suggestions}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
              />
              <ErrorMessage name="skills" component="div" />
              <label className="font-weight-bold d-block mt-3" htmlFor="owner">
                Owner
              </label>
              <Field
                component="select"
                name="owner"
                className="form-control"
                value={props.values.owner}
              >
                <option value="1">Miguel Estrada (id: 4234589)</option>
                <option value="2">Adam Smith (id: 7234587)</option>
              </Field>
              <ErrorMessage name="owner" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="contact"
              >
                Support Contact
              </label>
              <Field
                component="select"
                name="contact"
                className="form-control"
                value={props.values.contact}
              >
                <option value="1">Sam Adams (id: 5234587)</option>
                <option value="2">Will Cox (id: 9234587)</option>
              </Field>
              <ErrorMessage name="contact" component="div" />
            </Element>
            <Element name="location">
              <h4 id="location" className="mt-5">
                Location
              </h4>
              <hr />
              <label className="font-weight-bold d-block mt-3" htmlFor="client">
                Client
              </label>
              <Field
                component="select"
                name="client"
                className="form-control"
                value={props.values.client}
              >
                <option>Select Client</option>
                <option value="1">Chase</option>
                <option value="2">Bank of America</option>
                <option value="3">Verizon</option>
              </Field>
              <ErrorMessage name="client" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="project"
              >
                Project
              </label>
              <Field
                component="select"
                name="project"
                className="form-control"
                value={props.values.project}
              >
                <option>Select Project</option>
                <option value="1">Router installation</option>
                <option value="2">Hosted VOIP install</option>
                <option value="3">WAN migration</option>
              </Field>
              <ErrorMessage name="category" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="locationname"
              >
                Location Name
              </label>
              <Field type="text" name="locationname" className="form-control" />
              <ErrorMessage name="locationname" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="locationname"
              >
                Location Number
              </label>
              <Field
                type="text"
                name="locationnumber"
                className="form-control"
              />
              <ErrorMessage name="locationnumber" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="location"
              >
                Location
              </label>
              <Check
                type="radio"
                label={`Select location from contact manager`}
                id={`check-manager`}
              />
              <Check
                checked
                type="radio"
                label={`Create new location`}
                id={`check-new`}
              />
              <Check
                type="radio"
                label={`Virtual Offsite`}
                id={`check-virtual`}
              />
              <ErrorMessage name="location" component="div" />
            </Element>
            <Element name="address">
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="address"
              >
                Address
              </label>
              <Group controlId="formGridAddress1" className="mt-3">
                <Label>Line 1</Label>
                <Control
                  placeholder="1234 Main St"
                  value={props.values.address1}
                />
              </Group>

              <Group controlId="formGridAddress2">
                <Label>Line 2</Label>
                <Control
                  placeholder="Apartment, studio, or floor"
                  value={props.values.address2}
                />
              </Group>

              <Row>
                <Group as={Col} controlId="formGridCity">
                  <Label>City</Label>
                  <Control placeholder="Glendale" value={props.values.city} />
                </Group>

                <Group as={Col} controlId="formGridState">
                  <Label>State</Label>
                  <Control as="select">
                    <option value="az">AZ</option>
                  </Control>
                </Group>

                <Group as={Col} controlId="formGridZip">
                  <Label>Zip</Label>
                  <Control value={props.values.zip} />
                </Group>
              </Row>
            </Element>
            <Element name="scheduling">
              <h4 className="mt-5">Scheduling</h4>
              <hr />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="schedule"
              >
                Schedule Options
              </label>
              <Check
                checked
                type="radio"
                label={`Specific Date & Time`}
                id={`check-specific`}
              />
              <Check
                type="radio"
                label={`Set Arrival Window`}
                id={`set-arrival`}
              />
              <ErrorMessage name="location" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="datetime"
              >
                Date / Time
              </label>
              <Row>
                <Group as={Col} controlId="formStartDate">
                  <Label>Date</Label>
                  <Control value={props.values.startdate} />
                </Group>
                <Group as={Col} controlId="formStartTime">
                  <Label>Time</Label>
                  <Control value={props.values.starttime} />
                </Group>
              </Row>
              <Check checked type="radio" label={`AM`} id={`check-am`} />
              <Check type="radio" label={`PM`} id={`check-pm`} />
              <ErrorMessage name="datetime" component="div" />
            </Element>
            <Element name="pricing">
              <h4 id="pricing" className="mt-5">
                Pricing
              </h4>
              <hr />
              <Group controlId="form.PaymentType">
                <Label className="font-weight-bold d-block mt-3">
                  Payment Type
                </Label>
                <Control as="select">
                  <option>Flat Fee</option>
                </Control>
              </Group>
              <ErrorMessage name="paymenttype" component="div" />
              <label
                className="font-weight-bold d-block mt-3"
                htmlFor="flatfee"
              >
                Flat Fee
              </label>
              <Field
                type="text"
                name="flatfee"
                className="form-control"
                value="2100"
              />
              <ErrorMessage name="flatfee" component="div" />
              <Group controlId="form.PaymentTerms">
                <Label className="font-weight-bold d-block mt-3">
                  Payment Terms
                </Label>
                <Control as="select">
                  <option>7 Days(Best Practice)</option>
                </Control>
              </Group>
              <ErrorMessage name="paymentterms" component="div" />
            </Element>
            <Element name="documents">
              <h4 id="documents" className="mt-5">
                Documents
              </h4>
              <hr />
              <button type="submit" className="btn btn-primary">
                <i className="fas fa-cloud-upload-alt"></i> Upload New Document
              </button>
            </Element>
            <Element name="routing">
              <h4 id="routing" className="mt-5">
                Routing
              </h4>
              <hr />
              <Label className="font-weight-bold d-block mt-3">Publish</Label>
              <ErrorMessage name="publish" component="div" />
              <Check
                checked
                type="checkbox"
                label={`Post to Marketplace`}
                id={`check-marketplace`}
              />
              <Label className="font-weight-bold d-block mt-3">
                Invite Talent
              </Label>
              <Check
                type="radio"
                label={`Send to Specific Talent`}
                id={`check-direct-talent`}
              />
              <Check
                type="radio"
                label={`Auto-Invite`}
                id={`check-auto-invite`}
              />
              <Check
                type="radio"
                label={`Browse Talent Marketplace`}
                id={`check-talent`}
              />
              <ErrorMessage name="invite" component="div" />
            </Element>
            <Element name="insurance">
              <h4 id="insurance" className="mt-5">
                Insurance
              </h4>
              <hr />
              <Label className="font-weight-bold d-block mt-3">
                Required insurance
              </Label>
              <ErrorMessage name="insurance" component="div" />
              <Label className="font-weight-bold d-block mt-3">
                Coverage type
              </Label>
              <Check
                type="radio"
                label={`Workers compensation`}
                id={`check-workers-comp`}
                checked
              />
              <Check
                type="radio"
                label={`General liability`}
                id={`check-general-liablity`}
              />
              <Check
                type="radio"
                label={`Professional liablity`}
                id={`check-professional-liability`}
              />
              <Check
                type="radio"
                label={`Occupational and accident insurance`}
                id={`check-occ-acc`}
              />
            </Element>
          </Form>
        );
      }}
    </Formik>
  );
}

class CreateAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      initVals: null,
    };
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
    this._fetchPrefill();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  _fetchPrefill() {
    const url = `${BASE_URL}${PREFILL_PATH}`;
    const token = this.props.token;

    const getInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, getInit)
      .then(handleErrors)
      .then((prefillResponse) => {
        return prefillResponse.json();
      })
      .then((prefillData) => {
        this.setState({ loading: false });
        this.setState({ initVals: prefillData });
      });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col sm={4}>
            <Nav defaultActiveKey="/basics" className="flex-column">
              <Link
                href="#basics"
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="basics"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                The Basics
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="location"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Location
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="scheduling"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Scheduling
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="pricing"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Pricing
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="documents"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Documents
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="routing"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Routing
              </Link>
              <Link
                activeClass="font-weight-bold"
                className="text-dark nav-link"
                to="insurance"
                spy={true}
                smooth={true}
                duration={250}
                containerId="createAssignment"
              >
                Insurance
              </Link>
              {/* <Nav.Link href="#basics" eventKey="basics" className="text-dark font-weight-bold">The Basics</Nav.Link>
              <Nav.Link href="#location" eventKey="location" className="text-dark">Location</Nav.Link>
              <Nav.Link href="#scheduling" eventKey="scheduling" className="text-dark">Scheduling</Nav.Link>
              <Nav.Link href="#pricing" eventKey="pricing" className="text-dark">Pricing</Nav.Link>
              <Nav.Link herf="#documents" eventKey="documents" className="text-dark">Documents</Nav.Link>
              <Nav.Link href="#routing" eventKey="routing" className="text-dark">Routing</Nav.Link>
              <Nav.Link href="#insurance" eventKey="insurance" className="text-dark">Insurance</Nav.Link> */}
            </Nav>
          </Col>
          <Col sm={8} style={{ height: "400px", overflowY: "scroll" }}>
            <Element id="createAssignment">
              {!this.state.loading && this.state.initVals ? (
                <AssignmentForm
                  initVals={this.state.initVals}
                  token={this.props.token}
                  handleHide={this.props.handleHide}
                />
              ) : (
                <ReactLoading
                  type="spin"
                  color="#000000"
                  height={25}
                  width={25}
                  className="mx-auto mt-5"
                />
              )}
            </Element>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateAssignment;
