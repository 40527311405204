import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { withRouter } from 'react-router';
import { registerUser } from 'react-cognito';
import { ReactReduxContext } from 'react-redux'

import { Link } from 'react-router-dom';

import AuthWrapper from '../Components/AuthWrapper';

class RegisterForm extends React.Component {

  constructor(props) {
    super(props);

    const params = queryString.parse(this.props.location.search)
    this.state = {
      error: '',
      fullname: '',
      username: '',
      password: '',
      confirmPassword: '',
      email: params.email || '',
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  register = (store) => {
    const state = store.getState()
    const userPool = state.cognito.userPool
    const config = state.cognito.config

    registerUser(userPool, config, this.state.username, this.state.password, {
      email: this.state.email,
      name: this.state.fullname
    }).then(
      (action) => {
        store.dispatch(action)
        this.props.history.push('/')
      },
      error => this.setState({ error }))
  }

  onSubmit = (store, event) => {
    event.preventDefault();

    const { password, confirmPassword } = this.state
    // perform all neccassary validations
    if (password !== confirmPassword) {
      this.setState({ error: "Passwords don't match" })
    } else {
      this.register(store)
    }
  }

  changeFullname = (event) => {
    this.setState({ fullname: event.target.value })
  }

  changeUsername = (event) => {
    this.setState({ username: event.target.value })
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value })
  }

  changeConfirmPassword = (event) => {
    this.setState({ confirmPassword: event.target.value })
  }

  changeEmail = (event) => {
    this.setState({ email: event.target.value, username: event.target.value })
  }

  render = () => (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <AuthWrapper>
          <form onSubmit={(event) => this.onSubmit(store, event)}>
            <fieldset id="sign_up" className="ba b--transparent ph0 mh0 dark-gray">
              <legend className="f3 pv3 fw7">Get started today!</legend>
              <div className="brand-pink pb3">{this.state.error}</div>
              <div className="form-group">
                <label htmlFor="fullname">Full name</label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.fullname}
                  onChange={this.changeFullname}
                  required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  value={this.state.email}
                  onChange={this.changeEmail}
                  required />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control"
                  onChange={this.changePassword}
                  type="password"
                  required />
              </div>
              <div className="form-group">
                <label htmlFor="confirm-password">Confirm password</label>
                <input
                  className="form-control"
                  onChange={this.changeConfirmPassword}
                  type="password"
                  required />
              </div>
            </fieldset>
            <input
              className="btn btn-primary mt-4"
              value="Create account"
              type="submit" />
            <p className="black-40 pt3">Already have an account? <Link className="fw5 brand-pink no-underline" to="/">Sign in</Link></p>
          </form>
        </AuthWrapper>
      )}
    </ReactReduxContext.Consumer>
  )
}

RegisterForm.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(RegisterForm);
